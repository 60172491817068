import './Artists.scss';

const Artists = () => {
    return (<div className="artists">
        <div className="wrapper">
            <div className="artistswrapper">
                <p>Jeszcze nic tu nie ma! Jeśli jesteś artyst(k)ą i chcesz się zareklamować ze swoimi produktami, napisz na <a href="mailto:thepolishswiftie@gmail.com">thepolishswiftie@gmail.com</a> 🎉</p>
            </div>
        </div>
    </div>)
}

export default Artists;