import React from 'react';

const HouseLogo = () => {
  return (
    // <svg width="40" height="40" viewBox="0 0 485 479" xmlns="http://www.w3.org/2000/svg">
    //   <defs>
    //     <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
    //       <stop offset="0%" style={{ stopColor: '#FF0000', stopOpacity: 1 }} />
    //       <stop offset="100%" style={{ stopColor: '#FFC0CB', stopOpacity: 1 }} />
    //     </linearGradient>
    //   </defs>
    //   <path d="M0 142.5V485.295H166.423H317.912H484.336V142.5L242.168 0.962997L0 142.5Z">
    //     <animate
    //       attributeName="fill"
    //       values="#b5e9f6;url(#gradient);#b5e9f6"
    //       dur="5s"
    //       repeatCount="indefinite"
    //     />
    //   </path>
    // </svg>
    <div className='logo'>
        <img src='/images/taylorswift.jpeg' />
    </div>
  );
};

export default HouseLogo;
