import './WorkWithMe.scss';

const WorkWithMe = () => {
    return (<div className="wwm">
        <div className="wrapper">
            <div className="contentWrapper">
                <p>Chcesz nawiązać współpracę? Napisz na <a href="mailto:thepolishswiftie@gmail.com">thepolishswiftie@gmail.com</a> 🎉</p>
            </div>
        </div>
    </div>)
}

export default WorkWithMe;